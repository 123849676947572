const print = {
    elements: {
        $trigger: document.querySelectorAll('.js-print-trigger'),
        $target: document.querySelectorAll('.js-print-target'),
    },
    init() {
      this.elements.$trigger.forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            const targetSlug = e.currentTarget.dataset.printSlug;
            this.printElement(targetSlug);
        });
      });
    },
    printElement(slug) {
        const element = document.querySelector(`.js-print-target[data-print-slug="${slug}"]`);

        if (!element) {
            console.error(`Element with slug "${slug}" not found.`);
            return;
        }

        // Create a new iframe
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        // Write the content to the iframe
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.write('<html><head><title>Print</title>');

        // Get all link elements from the current document
        const cssLinks = document.querySelectorAll('link[rel="stylesheet"]');

        if (cssLinks.length > 0) {
            // Iterate through each CSS link
            cssLinks.forEach(link => {
                // Write the entire link element to the iframe
                iframeDoc.write(link.outerHTML);
            });
        } else {
            console.warn('No CSS stylesheets found in the current document.');
        }

        // Add print-specific styles
        iframeDoc.write(`
            <style>
            @media print {
                body {
                    -webkit-print-color-adjust: exact !important;
                    print-color-adjust: exact !important;
                    color-adjust: exact !important;
                }
                .no-print,
                .no-print * {
                    display: none !important;
                }
            }
            </style>
        `);

        iframeDoc.write('</head><body>');
        iframeDoc.write(element.innerHTML);
        iframeDoc.write('</body></html>');
        iframeDoc.close();

        // Safari-compatible load detection
        const checkIframeLoaded = () => {
            const doc = iframe.contentDocument || iframe.contentWindow.document;

            if (doc.readyState === 'complete') {
                // Show print background instructions
                alert("For best results, please ensure 'Print background colors and images' is enabled in your browser's print settings.");

                // Print the iframe
                iframe.contentWindow.print();

                // Remove the iframe after printing
                setTimeout(() => {
                    document.body.removeChild(iframe);
                }, 100);
            } else {
                setTimeout(checkIframeLoaded, 100);
            }
        };

        checkIframeLoaded();
    }
  };

  export default print;
