<template>
    <form
        method="get"
        @submit.prevent="submitForm"
        id="filters"
        ref="filtersElement"
    >
        <!-- Hidden page number input -->
        <input
            type="hidden"
            v-model="this.selectedPage"
            name="page"
            ref="page-input"
        />

        <!-- Other hidden inputs -->
        <input
            type="hidden"
            v-for="field in hiddenFields"
            :key="field.name"
            :name="field.name"
            :value="field.value"
        />

        <!-- Filters and View buttons -->
        <nav class="flex gap-6 md:items-center">
            <!-- Filters -->
            <div class="flex-grow grid md:grid-flow-col gap-4">
                <select class="bg-gray-8 p-4 text-sm rounded-md form-select" v-for="filter in filters" :key="filter.handle" :name="filter.handle" @change="updateFilter(filter.handle, $event.target.value)">
                    <option v-for="item in filter.items" :key="item.slug" :value="item.slug" :selected="item.selected">{{ item.label }}</option>
                </select>
            </div>

            <!-- View Toggles -->
            <div v-if="this.showViewToggle" class="max-md:flex-col flex gap-2 md:items-center">
                <button
                    :class="[
                        this.newView === 'list'
                        ? 'text-gray-12'
                        : 'text-gray-7',
                        'flex items-center justify-center hover:bg-gray-1'
                    ]"
                    @click="updateView('list')"
                >
                    <Icon
                        :name="'list'"
                        :class="[
                            this.newView === 'list'
                            ? 'fill-gray-12'
                            : 'fill-gray-7',
                            'w-9 h-9 p-1'
                        ]"
                    />
                </button>

                <button
                    :class="[
                        this.newView === 'grid'
                        ? 'text-gray-12'
                        : 'text-gray-7',
                        'flex items-center justify-center hover:bg-gray-1'
                    ]"
                    @click="updateView('grid')"
                >
                    <Icon
                        :name="'grid'"
                        :class="[
                            this.newView === 'grid'
                            ? 'fill-gray-12'
                            : 'fill-gray-7',
                            'w-9 h-9 p-1'
                        ]"
                    />
                </button>
            </div>
        </nav>
    </form>
</template>


<script>
    import axios from 'axios';
    import { ref, nextTick } from 'vue/dist/vue.esm-bundler';
    import Icon from './Icon.vue';

    export default {
        emits: ['isLoading', 'updateResults', 'updatePagination', 'updateView', 'paginate'],
        props: {
            hiddenFields: {
                type: [Array, Object],
                require: false
            },
            filters: {
                type: Array,
                require: true,
                default: []
            },
            endpoint: {
                type: String,
                require: true,
                default: ''
            },
            type: {
                type: String,
                require: true,
                default: 'module'
            },
            groups: {
                type: Array,
                require: false,
                default: []
            },
            view: {
                type: String,
                require: false,
                default: 'grid'
            },
            selected: {
                type: Object,
                require: false,
                default: {}
            },
            page: {
                type: Number,
                require: false,
                default: 1
            },
            totalPages: {
                type: Number,
                require: false,
                default: 1
            },
            showViewToggle: {
                type: Boolean,
                require: false,
                default: true
            },
            hiddenFields: {
                type: [Array, Object],
                require: false,
                default: []
            },
            isPreview: {
                type: Boolean,
                require: false,
                default: false
            }
        },
        components: {
            Icon
        },
        setup() {
            const firstLoad = ref(true);
            const filtersElement = ref(null);

            return {
                firstLoad,
                filtersElement
            };
        },
        data() {
            return {
                form: {},
                newView: this.view,
                selectedPage: this.page ?? 1
            }
        },
        methods: {
            updateFilter(field, value) {
                this.form[field] = value;

                this.$emit('paginate', 1);

                nextTick(() => {
                    this.getResults(this.form, this.newView);
                });
            },
            updateView(newView) {
                if (newView != this.newView) {
                    this.$emit('updateView', newView);

                    nextTick(() => {
                        this.getResults(this.form, newView);
                    });
                }
            },
            updatePage(page) {
                this.selectedPage = page;
                nextTick(() => {
                    this.getResults(this.form, this.newView);
                });
            },
            getInitialFormState() {
                let values = {};

                for (var i = 0; i < this.filters.length; i++) {
                    let filter = this.filters[i];
                    let handle = filter.handle;
                    let items = filter.items;

                    if (items) {
                        for (var j = 0; j < items.length; j++) {
                            let option = items[j];
                            if (option.selected) {
                                values[handle] = option.slug;
                            }
                        }
                    }
                }

                if (this.hiddenFields.length > 0) {
                    for (var i = 0; i < this.hiddenFields.length; i++) {
                        let field = this.hiddenFields[i];
                        values[field.name] = field.value;
                    }
                }

                this.form = values;
            },
            pushState(parameters) {
                const params = new URLSearchParams()

                for(const prop in parameters) {
                    params.append(prop, parameters[prop]);
                }

                history.replaceState(
                    { ...history.state, scrollY: window.scrollY ?? window.pageYOffset },
                    null,
                    window.location.href,
                )
                history.pushState(
                    { ...history.state },
                    null,
                    `${window.location.origin}${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`,
                )
            },
            getResults(formData, view) {
                // First, empty out the results and set the state to 'loading'
                this.$emit('isLoading', true);

                if (view && view != this.newView) {
                    this.newView = view;
                }

                // Start setting some initial data
                let params = null;
                let initialParams = { type: this.type, livepreview: this.isPreview };

                // The "Skill Explainers" don't use their "view" in their endpoint, but other types do
                let endpoint = null;
                if (this.type == 'skill-explainer') {
                    endpoint = `/api/${this.type}.json`;
                } else {
                    endpoint = `/api/explore-${this.newView}.json`;

                    initialParams['groups'] = this.groups;
                }

                // Pass in filter values, if present
                if (formData) {
                    this.form = formData;

                    params = { ...initialParams, ...formData };
                } else if (this.form) {
                    params = { ...initialParams, ...this.form };
                } else {
                    params = initialParams;
                }

                let isDifferentPage = this.selectedPage != params['page'];

                // Add the page number
                params['page'] = this.selectedPage;

                // Scroll to the top of the filters if we're on a different page,
                // to see the loading animation
                if (isDifferentPage && this.firstLoad == false) {
                    this.filtersElement.scrollIntoView();
                }

                axios.get(endpoint, { params: params })
                .then(response => {
                    let results = response.data.data;
                    this.$emit('updateResults', results);

                    // Figure out if there's another page of results, to show/hide "more" button
                    let meta = response.data.meta;
                    let pagination = meta ? meta.pagination : null;
                    if (pagination) {
                        this.$emit('updatePagination', pagination);
                        this.pages = pagination['total_pages'];
                    }

                    // Update the loading state
                    nextTick(() => {
                        this.$emit('isLoading', 'complete');

                        // Scroll to the top of the filters if we're on a different page
                        // to see the content
                        if (isDifferentPage && this.firstLoad == false) {
                            this.filtersElement.scrollIntoView();
                        } else {
                            this.firstLoad = false;
                        }
                    })
                });

                // Update the URL
                // this.pushState(params);
            }
        },
        mounted() {
            this.getInitialFormState();
        }
    }
</script>
