<template>
    <ul class="pt-12 grid gap-6 md:grid-cols-2 lg:grid-cols-4" v-if="results && results.length > 0">
        <li class="h-full flex justify-stretch items-stretch" v-for="card in results" :key="card.id">
            <Card :card="card" :currentuser="currentuser" color="var(--orange-2)" :is-preview="this.isPreview" />
        </li>
    </ul>
</template>

<script>
    import Card from '../cards/Card.vue';
    import Pagination from '../pieces/Pagination.vue';
    import { nextTick } from 'vue/dist/vue.esm-bundler';

    export default {
        props: {
            results: {
                type: [Object, Array],
                require: false,
                default: []
            },
            currentuser: {
                type: Object,
                require: false,
                default: () => {}
            },
            type: {
                type: String,
                require: true,
                default: 'module'
            },
            isPreview: {
                type: Boolean,
                require: false,
                default: false
            }
        },
        components: {
            Card,
            Pagination
        },
        data() {
            return {}
        },
        methods: {
        },
        mounted() {
        }
    }
</script>
