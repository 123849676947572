// Get the global css
import '/src/css/app.css';

// Import our third-party scripts and the utilities that bundle them
import { createApp, ref } from 'vue/dist/vue.esm-bundler.js';
import 'lazysizes';
import Tips  from './utils/tips.js';
import { VideoPlayers } from './utils/videoPlayers';
import CountUp from './utils/countUp.js';
import Slider from './utils/slider.js';
import Tabs  from './utils/tabs.js';
import Modal  from './utils/modal.js';
import Print from './utils/print.js';
import NewsletterHandler from './utils/newsletterHandler.js';

// Import our first-party components
import ShareThis from './components/ShareThis.vue';
import CardsGrid from './components/CardsGrid.vue';
import GlossaryWrapper from './components/GlossaryWrapper.vue';
import GlossaryToggle from './components/GlossaryToggle.vue';
import HeaderNavigation from './components/HeaderNavigation.vue';
import Taxonomy from './components/Taxonomy.vue';
import TaxonomyResults from './components/TaxonomyResults.vue';

// Import the app-level storage
import { store } from './store.js';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

// Delay init of lazyloading plugin
const lazySizesConfig = lazySizes.cfg;
lazySizesConfig.init = false;

/* Init our components that don't rely on content that gets injected dynamically via Blitz,
meaning that this content CAN (and maybe SHOULD) be cached. So, not user dropdowns or cards,
which incorporates a user-based Favorites functionality. */
Tips.init();
VideoPlayers();
CountUp.init();
Slider.init();
Tabs.init();
Modal.init();
Print.init();

/* Across this site, every Vue app waits to be initialized until
AFTER Blitz has loaded in all the dynamic content because the Vue
components are all included via Blitz AS dynamic content. So this
prevents the Vue components from trying to initialize BEFORE the
markup containing them has been loaded into the window. The signal
to start that loading is a custom Blitz javascript event that we
listen to via our own javascript in each file for each page.

The problem with this approach is that Blitz is not enabled on
every environment. It's possible that, if Blitz is not enabled in
the environment, the Blitz event is never run and so the listener
is never called and the Vue components are never initialized.

To circumvent this, we have a signal in the markup itself that tells
us whether or not Blitz is enabled. If it is, we just wait for the
event to be called automatically by Blitz. If it's NOT, then we call
it ourselves immediately. This has no drawback, since there's no need
to wait for the Vue-containing markup to be loaded in, since it's not
being cached outside the document flow with Blitz.
*/

// Create the header navigation
const headerNavigation = createApp({
    components: {
        HeaderNavigation
    },
    mounted() {
        lazySizes.init()
    },
});
headerNavigation.mount('#header-navigation');

let main = document.querySelector('main');
let hasBlitz = main.dataset.hasBlitz;

if (hasBlitz == 'no-blitz') {
    const event = new Event('afterBlitzInjectAll');
    document.dispatchEvent(event);
}

/* Init our un-cache-able components */
document.addEventListener('afterBlitzInjectAll', function (event) {
    // The Newsletter form gets injected, too
    NewsletterHandler.init();

    // Create and init every ShareThis component
    document.querySelectorAll('.v-share-this').forEach((el) => {
        const shareThisApp = createApp({
            components: { ShareThis },
            mounted() { lazySizes.init() }
        }).mount(el);
    });

    // Create and init every Cards Grid component, which encompasses several types of Stream blocks
    document.querySelectorAll('.v-cards-grid').forEach((el) => {
        const cardsGridApp = createApp({
            components: { CardsGrid },
            mounted() { lazySizes.init() }
        }).mount(el);
    });

    // Create and init every Glossary component
    document.querySelectorAll('.v-glossary-wrapper').forEach((el) => {
        const glossaryWrapperApp = createApp({
            components: { GlossaryWrapper, GlossaryToggle },
            mounted() { lazySizes.init() }
        }).mount(el);
    });

    /* We want the whole header navigation to be loaded in WITH BLITZ in its default, "no user, no search" state.
        Then, after everything has been fetched with Blitz, we'll update it with the correct, newly-available data.
        We'll do that by grabbing the search and user data from the dynamically injected hidden divs here, passing
        that data to the global store, and forcing the header component to reload with the correct, newly-fetched data.
    */

    // Get the newly-updated values
    let userIdContainer = document.querySelector('#user-id-container');
    let userId = userIdContainer ? userIdContainer.dataset.userId : null;
    let userName = userIdContainer ? userIdContainer.dataset.userName : null;
    let loginUrl = userIdContainer ? userIdContainer.dataset.loginUrl : null;
    let searchContainer = document.querySelector('#global-search-params');
    let searchParams = searchContainer ? searchContainer.dataset.params : null;
    // Update the store with that data
    store.updateUserId(userId);
    store.updateUserName(userName);
    store.updateParams(searchParams);
    store.updateLoginUrl(loginUrl);

    // Create and init every Taxonomy component
    document.querySelectorAll('.v-taxonomy').forEach((el) => {
        const taxonomyApp = createApp({
            components: { Taxonomy },
            mounted() { lazySizes.init() }
        }).mount(el);
    });

    // Create and init every Taxonomy Results component
    document.querySelectorAll('.taxonomy-results').forEach((el) => {
        const app = createApp({
            components: { TaxonomyResults },
            mounted() { lazySizes.init() }
        }).mount(el);
    });

    // Find every "download" block on the page and update the CSRF token
    document.querySelectorAll('form.download-form').forEach((form) => {
        console.log(form);
        fetch('/actions/blitz/csrf/token')
            .then(result => { return result.text(); })
            .then(result => {
                let csrfToken = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
                csrfToken.value = result;
            });
    });

    // Add a 'skeleton loader' animation for the text blocks
    document.querySelectorAll('.skeleton').forEach((el) => {
        el.classList.add('opacity-0');
        setTimeout(el.remove(), 3500);
        //el.remove();
    })
});

