let youtubeVideosQueue = [];
let activePlayers = [];

export function VideoPlayers() {
    const videoContainers = document.querySelectorAll('.js-video');
    if (videoContainers.length === 0) return;

    videoContainers.forEach(container => {
        const videoThumbnail = container.querySelector('.js-video-thumbnail');
        const videoPlayer = container.querySelector('.js-video-player');

        if (!videoThumbnail || !videoPlayer) return;

        videoThumbnail.addEventListener('click', () => playVideo(videoThumbnail, videoPlayer));
        videoThumbnail.addEventListener('keypress', (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                playVideo(videoThumbnail, videoPlayer);
            }
        });
    });

    if (document.querySelector('.js-video-player[src*="youtube.com"]')) {
        loadYouTubeAPI();
    }
}

function playVideo(thumbnail, player) {
    thumbnail.classList.add('is-loading');
    pauseAllVideos();
    if (player.src.includes('youtube.com')) {
        playYouTubeVideo(thumbnail, player);
    } else {
        playHTMLVideo(thumbnail, player);
    }
}

function pauseAllVideos() {
    activePlayers.forEach(player => {
        if (player.getPlayerState && player.getPlayerState() === YT.PlayerState.PLAYING) {
            player.pauseVideo();
        } else if (player.pause && typeof player.pause === 'function') {
            player.pause();
        }
    });
}

function playYouTubeVideo(thumbnail, player) {
    const videoId = extractYouTubeId(player.src);
    if (!videoId) {
        console.error('Unable to extract YouTube video ID');
        return;
    }

    const placeholderDiv = document.createElement('div');
    placeholderDiv.id = 'youtube-player-' + videoId;
    player.parentNode.replaceChild(placeholderDiv, player);

    youtubeVideosQueue.push({ id: placeholderDiv.id, videoId: videoId });

    if (window.YT && window.YT.Player) {
        createYouTubePlayer(thumbnail, placeholderDiv.id, videoId);
    }
}

function loadYouTubeAPI() {
    if (!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    window.onYouTubeIframeAPIReady = function() {
        youtubeVideosQueue.forEach(video => {
            createYouTubePlayer(null, video.id, video.videoId);
        });
    };
}

function createYouTubePlayer(thumbnail, playerId, videoId) {
    const player = new YT.Player(playerId, {
        height: 'auto',
        width: '100%',
        videoId: videoId,
        events: {
          'onReady': (event) => {
            activePlayers.push(event.target);
            event.target.playVideo();
          },
          'onStateChange': (event) => {
            if (event.data == YT.PlayerState.PLAYING) {
              const element = document.getElementById(playerId);
              element.classList.add('!opacity-100');

              if (thumbnail) {
                thumbnail.style.display = 'none';
              }
            }
          }
        }
    });
}

function playHTMLVideo(thumbnail, player) {
    if (typeof player.play === 'function') {
        activePlayers.push(player);
        player.play().catch(error => {
            console.warn('Error playing video:', error);
        });

        player.addEventListener('playing', () => {
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
        });
    } else {
        console.warn('Video player does not support HTML5 video API.');
    }
}

function extractYouTubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
}
